<template>
    <div>
        <el-card class="card-pub-notice card-ext-center-title customcard">
            <el-button type="primary" @click="derive()" v-if="deriveButton" class="derive_button">导出</el-button>
            <el-form :model="dataForm" :rules="dataRule" ref="handleForm" :disabled="handleDisible">
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item :label="'消缺单位'" prop="handleUnit">
                            <el-input v-model="dataForm.handleUnit" placeholder="请输入消缺单位"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="'消缺负责人'" prop="handlePerson">
                            <el-input v-model="dataForm.handlePerson" placeholder="请输入消缺单位"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item :label="'消缺开始-消缺结束时间'" prop="handleTimeRange">
                            <el-date-picker
                                v-model="dataForm.handleTimeRange"
                                type="datetimerange"
                                range-separator="至"
                                align="right"
                                unlink-panels
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd hh-mm"
                                format="yyyy-MM-dd hh-mm"
                                class="range_time"
                                @change="timeChange"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item :label="'处理时长'" prop="handleTime">
                            <el-input v-model="dataForm.handleTime" disabled> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <el-form-item :label="'处理情况'" prop="handleInfo">
                            <el-input v-model="dataForm.handleInfo" placeholder="请输入处理情况" type="textarea"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <el-form-item :label="'预防措施'" prop="preventiveMeasure">
                            <el-input v-model="dataForm.preventiveMeasure" placeholder="请输入处理情况" type="textarea"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <el-form-item :label="'备注'" prop="remark">
                            <el-input v-model="dataForm.remark" placeholder="请输入备注" type="textarea"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="消缺后照片" style="margin-top: 20px">
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item>
                        <ex-uploader v-model="dataForm.imgeUrl"> </ex-uploader>
                    </el-form-item>
                </el-row>
            </el-form>
        </el-card>
        <br />
    </div>
</template>

<script>
import {previewPdf, fileDownload} from '@/utils';

export default {
    name: 'handle',
    components: {},
    data() {
        return {
            dataRule: {
                handleUnit: [{required: true, message: '请输入消缺单位', trigger: 'blur'}],
                handlePerson: [{required: true, message: '请输入消缺负责人', trigger: 'blur'}],
            },
            dataForm: {
                handleUnit: '',
                handlePerson: '',
                handleTimeRange: '',
                handleTime: '',
                handleInfo: '',
                preventiveMeasure: '',
                remark: '',
                imgeUrl: [],
            },
            handleDisible: false,
            instance: {},
            deriveButton: false,
        };
    },
    methods: {
        async init(dataForm) {
            if (dataForm != null) {
                this.dataForm = dataForm;
            }
            this.closeDeriveButton();
        },
        activateForm(handleDisible) {
            this.clearform();
            this.handleDisible = handleDisible;
        },
        closeForm() {
            this.handleDisible = true;
            this.$refs.handleForm.clearValidate();
        },
        createForm() {
            return this.dataForm;
        },
        derive() {
            fileDownload('/business/ElecDispatchFlawManage/workTaskInformDownload', {
                method: 'POST',
                data: this.dataForm,
            });
        },
        activateDeriveButton() {
            this.deriveButton = true;
        },
        closeDeriveButton() {
            this.deriveButton = false;
        },
        clearform() {
            this.dataForm = {
                handleUnit: '',
                handlePerson: '',
                handleTimeRange: '',
                handleTime: '',
                handleInfo: '',
                preventiveMeasure: '',
                remark: '',
                imgeUrl: [],
            };
        },
        timeChange(val) {
            let begin = val[0];
            let end = val[1];
            this.dataForm.handleTime = this.$dayjs(end).diff(begin, 'hour');
        }
    },
};
</script>
<style lang="less" scoped>
.el-form-item {
    margin: 0px;
}
form.el-form {
    .el-date-editor {
        width: 100%;
    }
}
</style>
