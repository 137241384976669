<template>
    <div>
        <el-card class="customcard">
            <div>
                <el-button type="primary" @click="derive()" v-if="deriveButton" class="derive_button">导出</el-button>
                <el-form
                    :model="dataForm"
                    :rules="dataRule"
                    ref="receiptForm"
                    :disabled="receiptDisabled"
                    label-position="top"
                >
                    <el-row :gutter="24">
                        <el-col :span="6">
                            <el-form-item :label="'审核时间'" prop="time">
                                <el-input v-model="dataForm.time" placeholder="审核时间" disabled> </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item :label="'审核意见'" prop="content">
                                <el-input v-model="dataForm.content" placeholder="请输入审核意见" type="textarea"> </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'receipt',
    components: {},
    data() {
        return {
            receiptDisabled: false,
            deriveButton: false,
            dataForm: {
                time: '',
                content: '',
            },
            dataRule: {
                content: [{required: true, message: '请填写审核意见', trigger: 'blur'}],
            },
        };
    },
    methods: {
        async init(dataForm) {
            if (dataForm != null) {
                this.dataForm = dataForm;
            }
            this.closeDeriveButton();
        },
        activateForm(receiptDisabled) {
            this.clearform();
            this.receiptDisabled = receiptDisabled;
        },
        createForm() {
            return this.dataForm;
        },
        closeForm() {
            this.receiptDisabled = true;
            this.$refs.receiptForm.clearValidate();
        },
        derive() {
            fileDownload('/business/ElecDispatchFlawManage/workTaskInformDownload', {
                method: 'POST',
                data: this.dataForm,
            });
        },
        activateDeriveButton() {
            this.deriveButton = true;
        },
        closeDeriveButton() {
            this.deriveButton = false;
        },
        clearform() {
            this.dataForm = {
                time: '',
                content: '',
            };
        },
    },
};
</script>


<style lang="less" scoped>
// table {
// background: #161d30;
// text-align: center;
// .table_beizhu {
//     width: 25%;
// }
// .datetime_picker {
//     width: 30%;
// }
// .table_td {
//     width: 10%;
// }
// }
.el-row {
    .button {
        text-align: center;
        // .el-button--primary {
        //     background: #0c0e13;
        // }
    }
}
.el-form-item {
    margin: 0px;
}
.el-input__inner {
    width: 100%;
}
/deep/.el-button.is-disabled {
    background-color: #0b1020;
    border-color: #0b1020;
}
form.el-form {
    .el-date-editor {
        width: 100%;
    }
}
</style>

