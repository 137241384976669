<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100" style="margin: 20px">
        <div style="margin: 0 20px 20px 20px" v-show="listVisible" class="ui-layout-content--full ui-layout-flex--1">
            <el-row>
                <el-button type="primary" v-if="deriveVisible" @click="deriveFlawApplication()">导出缺陷记录</el-button>
                <el-button type="primary" @click="handleCommand()" v-if="addFlawApplication">新建缺陷申请</el-button>
            </el-row>
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="initList"
                listField="items"
                :columns="columns"
                pageSizeKey="size"
                totalField="totalItemCount"
                pageIndexKey="current"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
            >
                <template slot="append">
                    <el-table-column align="center" label="操作" type="action" width="250">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="show(scope.row)" v-if="showFlawApplication"
                            >查看
                            </el-button
                            >
                            <el-button type="primary" @click="handle(scope.row)" v-if="handlebutton(scope.row)"
                            >处理
                            </el-button
                            >
                            <el-button type="primary" @click="deleteForm(scope.row)" v-if="deletebutton(scope.row)"
                            >删除
                            </el-button
                            >
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
            <el-dialog title="导出" :visible.sync="dialogVisible" width="30%">
                <div>
                    <el-select v-model="isAll" placeholder="请选择">
                        <el-option
                            v-for="item in deriveOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div v-show="!listVisible ? true : false">
            <div style="text-align: center" class="detailTitle">
                <span
                ><h2>{{ title }}</h2></span
                >
            </div>
            <div style="padding: 30px">
                编号：{{code}}
                <!--新建申请-->
                <ex-card
                    v-show="FlawApplictionVisible"
                    headerName="缺陷申请单"
                    name="flawApplication"
                    style="margin-top: 30px"
                >
                    <flaw-application
                        ref="flawApplication"
                        @refreshDataList="refreshDataList"
                        :facilityVoltageOption="facilityVoltageOption"
                        :questionClassifyOption="questionClassifyOption"
                        :flawLevelOption="flawLevelOption"
                        :notifyUnitsOptions="notifyUnitsOptions"
                        :substationOrLineNameOption="substationOrLineNameOption"
                        :userList="userList"
                    ></flaw-application>
                </ex-card>
                <!--审核-->
                <ex-card headerName="审核" name="audit" style="margin-top: 30px">
                    <audit ref="audit"></audit>
                </ex-card>
                <!--处理-->
                <ex-card v-show="handleVisible" headerName="处理情况填写" name="handle" style="margin-top: 30px">
                    <handle
                        ref="handle"
                        :facilityVoltageOption="facilityVoltageOption"
                        :notifyUnitsOptions="notifyUnitsOptions"
                        @refreshDataList="refreshDataList"
                    >
                    </handle>
                </ex-card>
                <!--消缺（处理审核） 和处理模块一起显示/隐藏-->
                <ex-card v-show="handleVisible" headerName="消缺" name="receipt" style="margin-top: 30px">
                    <receipt ref="receipt"></receipt>
                </ex-card>
            </div>
            <div class="submit-btn-group" v-if="buttonVisible">
                <el-button @click="closeCollapse()">关闭</el-button>
                <el-button @click="createRceiptApplication()" type="primary" v-if="buttonsubmitVisible">{{
                    buttonText
                    }}
                </el-button>
                <el-button @click="reject()" type="primary" v-if="hasRejectBtn">驳回</el-button>
                <el-button
                    @click="$refs.flawApplication.createApplication()"
                    v-if="flawApplicationVisble"
                    type="primary"
                >提交
                </el-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import FlawApplication from './flawApplication';
import Handle from './handle';
import Receipt from './receipt';
import dayjs from 'dayjs';
import Audit from './audit';

const deriveOptions = [
    {
        label: '当前页面',
        value: 0
    },
    {
        label: '全部',
        value: 1
    }
];

export default {
    name: 'index',
    data() {
        return {
            FlawApplictionVisible: false,
            auditVisible: false, //审核
            handleVisible: false, // 处理情况填写
            flawApplicationVisble: false,
            receiptVisible: false,
            selectedRow: null,
            isAll: '',
            dialogVisible: false,
            deriveOptions,
            notifyUnitsOptions: [],
            listVisible: true,
            facilityVoltageOption: [],
            questionClassifyOption: [],
            flawLevelOption: [],
            substationOrLineNameOption: [],
            substationOrLineNameOption_: [],
            facilityNameOption: [],
            handleResultOption: [],
            handleClassifyOption: [],
            businesStatusOption: [],
            userList: [],
            instances: [],
            curInstances: {},
            buttonText: '提交',
            buttonVisible: true,
            buttonsubmitVisible: true,
            hasRejectBtn: false, //是否显示驳回按钮
            rejectNodeFrom: '',
            rejectNodeTo: '',
            businessStatus: '',
            addFlawApplication: false, // 运维新建
            deriveVisible: false,
            showFlawApplication: false,
            nextRoles: [],
            Roles: [],
            title: '',
            code: '--',
            instancesMap: {}
        };
    },
    components: {
        FlawApplication,
        Handle,
        Receipt,
        Audit
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'code',
                    label: '编号',
                    render: (row) => {
                        return row.data.code ? row.data.code : '---';
                    }
                },
                {
                    prop: 'data.flawEstate',
                    label: '缺陷等级',
                    render: (row) => {
                        //
                        if (row.data.flawApplication != null && row.data.flawApplication.flawEstate !== '') {
                            let obj = this.flawLevelOption.filter(
                                (item) => item.value === row.data.flawApplication.flawEstate
                            );
                            return obj.length > 0 ? obj[0].name : '---';
                        }
                        return '---';
                    }
                },
                {
                    prop: 'data.flawApplication.substationOrLineName[0]',
                    label: '线路/变电站名称',
                    formatter: function(row) {
                        if (row.data.flawApplication != null && row.data.flawApplication.substationOrLineName != null) {
                            // ;
                            let indexList = row.data.flawApplication.substationOrLineName;
                            let func = this.handleTreeData(indexList.length, indexList);
                            let data = func(this.substationOrLineNameOption);
                            return data.length > 0 ? data[0].name : '--';
                        }
                        return '---';
                    }.bind(this)
                },
                {
                    prop: 'data.flawApplication.facilityName[0]',
                    label: '设备名称',
                    formatter(row) {
                        return row.data.flawApplication != null && row.data.flawApplication.facilityName != null
                            ? row.data.flawApplication.facilityName
                            : '---';
                    }
                },
                {
                    prop: 'businessStatus',
                    label: '业务状态',
                    render: (row) => {
                        let instance = this.instances.filter(
                            (item) => item.records[item.records.length - 1].id === row.id
                        );
                        if (instance.length > 0) {
                            let obj = this.businesStatusOption.filter((item) => {
                                if (instance[0].nextNode != null) {
                                    return item.value === instance[0].nextNode.code;
                                }
                                return false;
                            });
                            return obj.length > 0 ? obj[0].name : '已消缺';
                        }
                        return '---';
                    }
                },
                /*{
                    prop: 'data.handleRresult',
                    label: '处理结果',
                    render: (row) => {
                        if (row.data.receiptForm != null && row.data.receiptForm.handleResult !== '') {
                            let instance = this.instances.filter(
                                (item) => item.records[item.records.length - 1].id === row.id
                            );
                                // ;
                            if (instance.length > 0 && instance[0].nextNode != null) {
                                let businesStatus = this.businesStatusOption.filter((item) => {
                                    if (instance.length > 0 && instance[0].nextNode != null) {
                                        return item.value === instance[0].nextNode.code;
                                    }
                                    return false;
                                });
                                if (businesStatus[0].code === 'elec_dispatcn_flaw_add_work_issued') {
                                    return '---';
                                }
                            }
                            let handleResultName = this.handleResultOption.filter(
                                (item) => item.value === row.data.receiptForm.handleResult
                            );
                            return handleResultName.length > 0 ? handleResultName[0].name : '---';
                        }
                        return '---';
                    }
                },*/
                {
                    prop: 'data.flawApplicationForm.discoveryUnits',
                    label: '申请提交单位',
                    render: (row) => {
                        //
                        if (row.data.flawApplication != null) {
                            return row.data.flawApplication.discoveryUnits != null
                                ? row.data.flawApplication.discoveryUnits
                                : '---';
                        }
                    }
                },
                {
                    prop: 'startTime',
                    label: '申请提交时间',
                    formatter: (row) => {
                        if (row) {
                            return dayjs.utc(row.startTime).add(8, 'hours').format('YYYY-MM-DD HH-mm');
                        }
                    }
                },
                {
                    prop: 'data.handleForm.handleUnit',
                    label: '任务处理单位',
                    /*formatter: (row, cloums, da, index) => {
                        //
                        if (row.data.handleForm != null && row.data.handleForm.notifyUnits != null) {
                            let obj = this.notifyUnitsOptions.filter((i) => i.id === row.data.handleForm.notifyUnits);
                            return obj.length > 0 ? obj[0].name : '---';
                        }
                        return '---';
                    }*/
                    formatter(row) {
                        return row.data.handleForm != null && row.data.handleForm.handleUnit != null
                            ? row.data.handleForm.handleUnit
                            : '---';
                    }
                },
                /*{
                    prop: 'data.handleForm.limitedPeriod',
                    label: '任务处理期限',
                    formatter: (row, cloums, da, index) => {
                        if (row.data.handleForm != null && row.data.handleForm.limitedPeriod != null) {
                            return row.data.handleForm.limitedPeriod;
                        }
                        return '---';
                    }
                },*/
                /*{
                    prop: 'data.receipt.absentEndTime',
                    label: '任务完成时间',
                    formatter: (row, cloums, da, index) => {
                        if (row.data.receiptForm != null && row.data.receiptForm.absentEndTime != null) {
                            let instance = this.instances.filter(
                                (item) => item.records[item.records.length - 1].id === row.id
                            );
                            if (instance.length > 0 && instance[0].nextNode != null) {
                                let businesStatus = this.businesStatusOption.filter((item) => {
                                    if (instance.length > 0 && instance[0].nextNode != null) {
                                        return item.value === instance[0].nextNode.code;
                                    }
                                    return false;
                                });
                                if (businesStatus[0].code === 'elec_dispatcn_flaw_add_work_issued') {
                                    return '---';
                                }
                            }
                            return row.data.receiptForm.absentEndTime;
                        }
                        return '---';
                    }
                }*/
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'code',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入缺陷编号',
                    },
                    {
                        prop: 'flawLevel',
                        label: '',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择缺陷等级',
                        options: this.flawLevelOption,
                        labelKey: 'name',
                        valueKey: 'value'
                    },
                    {
                        prop: 'businessStatus',
                        label: '',
                        itemType: 'select',
                        clearable: true,
                        placeholder: '请选择业务状态',
                        options: this.businesStatusOption,
                        labelKey: 'name',
                        valueKey: 'code'
                    },
                    /*{
                        prop: 'handleResult',
                        label: '',
                        itemType: 'select',
                        clearable: true,
                        placeholder: '请选择处理结果',
                        options: this.handleResultOption,
                        labelKey: 'name',
                        valueKey: 'value'
                    },*/
                    /*{
                        prop: 'notifyUnits',
                        label: '',
                        itemType: 'select',
                        clearable: true,
                        placeholder: '请选择下发处理单位',
                        options: this.notifyUnitsOptions,
                        labelKey: 'name',
                        valueKey: 'id'
                    }*/
                ]
            };
        }
    },
    mounted() {
        this.$client.getPowerGridsTrees().then((res) => {
            this.substationOrLineNameOption = res.data;
            this.facilityNameOption = res.data;
        });
    },
    created() {
        let nextCode = ['FLAW_MANAGE', 'FJB', 'KZYW']; // 5 , 15 , 54
        //运维新建
        this.$store.state.userInfo.roleCodeList.forEach((e) => {
            switch (e) {
                case 'KZYW': // 运维
                    this.showFlawApplication = true;
                    this.addFlawApplication = true;
                    this.deriveVisible = true;
                    break;
                case 'FLAW_MANAGE': //缺陷
                    // this.addFlawApplication = true;
                    this.showFlawApplication = true;
                    this.deriveVisible = true;
                    break;
                case 'FJB': //发计部
                    this.showFlawApplication = true;
                    break;
                default:
                    this.showFlawApplication = true;
            }
        });
        let codes = [
            'elec_flawLevel', //缺陷等级
            'elec_questionClassification', //问题分类
            'voltage', //电压等级
            'elec_handleResult', //处理结果
            'elec_handle_classify', //处理措施分类
            'elec_flaw_businesStatus' // 业务状态
        ];
        this.$client.listDictionaryChildrenArr(codes).then((res) => {
            this.facilityVoltageOption = res.data.voltage;
            this.questionClassifyOption = res.data.elec_questionClassification;
            this.flawLevelOption = res.data.elec_flawLevel;
            this.handleResultOption = res.data.elec_handleResult;
            this.handleClassifyOption = res.data.elec_handle_classify;
            this.businesStatusOption = res.data.elec_flaw_businesStatus;
        });
        this.$client.getOrgUnitByOrgTypeAndCode({orgType: 'department', code: 'WB'}).then((res) => {
            this.notifyUnitsOptions = res.data;
        });

        this.$client.getRolesBycodes(nextCode).then((res) => {
            this.Roles = res.data;
        });
        this.$client.getOrgUnitUserListByOrgId({unitId: this.$store.state.userInfo.orgList[0].id}).then((res) => {
            this.userList = res.data.records;
        });
        this.$client.workflowInstanceQueryList({DefinitionCode: 'elec_dispatcn_flaw'}).then(async (res) => {
            this.instances = res.data.items;
        });
    },
    methods: {
        handleCommand() {
            this.flawApplicationVisble = true;
            this.listVisible = false;
            this.buttonVisible = true;
            this.buttonsubmitVisible = false;
            this.FlawApplictionVisible = true;
            this.hasRejectBtn = false;
            this.title = '缺陷申报';
            this.$nextTick(() => {
                this.$refs.flawApplication.activateForm();
                this.$refs.audit.activateForm(true);
            });
        },
        closeCollapse() {
            this.listVisible = true;
            this.handleVisible = false;
            this.auditVisible = false;
            this.FlawApplictionVisible = false;
            this.receiptVisible = false;
            this.closeForm();
        },
        deriveFlawApplication() {
            this.dialogVisible = true;
        },
        /**
             * 刷新列表
             */
        refreshDataList() {
            this.closeCollapse();
            this.instancesMap = {};
            this.$client.workflowInstanceQueryList({DefinitionCode: 'elec_dispatcn_flaw'}).then(async (res) => {
                this.instances = res.data.items;
                this.$refs.searchTable.searchHandler();
            });
        },
        async initList(params) {
            params.DefinitionCode = 'elec_dispatcn_flaw';
            params.flawLevel ? (params['extraProp.flawEstate'] = params.flawLevel) : null;
            params.notifyUnits ? (params['extraProp.notifyUnits'] = params.notifyUnits) : null;
            params.businessStatus ? (params['extraProp.curNodeCode'] = params.businessStatus) : null;
            params.handleResult ? (params['extraProp.handleResult'] = params.handleResult) : null;
            params.code ? (params['extraProp.code'] = params.code) : null;
            // params.type = 'pending';
            let ids = [];
            let instanceIds = [];
            //获取实例列表
            await this.$client.workflowInstanceQueryList(params).then(async (res) => {
                ids = res.data.items.map((item) => item.records[item.records.length - 1].id);
                instanceIds = res.data.items.map(item => item.id);
            });
            params.ids = ids;
            return new Promise((resolve, reject) => {
                this.$client.getQueryRecords(params).then((res) => {
                    let data = res.data;
                    let i = 0;
                    data.items.forEach((e) => {
                        e.instanceId = instanceIds[i++];
                    });
                    resolve({data});
                });
            });
        },
        async handle(data) {
            this.listVisible = false;
            this.buttonsubmitVisible = false;
            this.flawApplicationVisble = false;
            let instance = this.instances.filter((item) => item.records[item.records.length - 1].id === data.id);
            this.curInstances = instance;
            let businesStatus = this.businesStatusOption.filter((item) => {
                if (instance.length > 0 && instance[0].nextNode != null) {
                    return item.value === instance[0].nextNode.code;
                }
                return false;
            });
            let code = businesStatus.length > 0 ? businesStatus[0].code : 'elec_dispatcn_flaw_audit';
            let dataForm = {};
            await this.$client.getQueryRecords({ids: [data.id]}).then(async (res) => {
                dataForm = res.data.items.map((item) => item.data);
            });
            //设置流程code
            this.code = dataForm.code;
            //流程对应表单
            this.isWorkForm(code);
            //激活表单
            //判断是否是填写状态
            let isFill = code === 'elec_dispatcn_flaw_1';
            this.activateAndGiveForm(dataForm, isFill);
            switch (code) {
                case 'elec_dispatcn_flaw_1': //填写
                    this.buttonText = '提交';
                    this.title = '缺陷申报';
                    this.buttonVisible = true;
                    //是否有驳回
                    this.hasRejectBtn = false;
                    if (this.$store.state.userInfo.roleCodeList.includes('FLAW_MANAGE')) {
                        this.buttonsubmitVisible = true;
                    }
                    break;
                case 'elec_dispatcn_flaw_add_apply': //待审核
                    this.buttonText = '提交';
                    this.title = '工作任务通知下发';
                    this.buttonVisible = true;
                    //是否有驳回
                    this.hasRejectBtn = true;
                    this.rejectNodeFrom = 'elec_dispatcn_flaw_add_work_issued';
                    this.rejectNodeTo = 'elec_dispatcn_flaw_add_apply';
                    if (this.$store.state.userInfo.roleCodeList.includes('FLAW_MANAGE')) {
                        this.buttonsubmitVisible = true;
                    }
                    break;
                case 'elec_dispatcn_flaw_add_work_issued': //执行中
                    this.buttonText = '提交';
                    this.title = '缺陷回执处理';
                    this.buttonVisible = true;
                    //是否有驳回
                    this.hasRejectBtn = false;
                    if (this.$store.state.userInfo.roleCodeList.includes('KZYW')) {
                        this.buttonsubmitVisible = true;
                    }
                    break;
                case 'elec_dispatcn_flaw_add_receipt': //待消缺
                    this.buttonVisible = true;
                    this.title = '审核';
                    this.buttonsubmitVisible = true;
                    //是否有驳回
                    this.hasRejectBtn = true;
                    this.rejectNodeFrom = 'elec_dispatcn_flaw_audit';
                    this.rejectNodeTo = 'elec_dispatcn_flaw_add_receipt';
                    break;
                case 'elec_dispatcn_flaw_audit': //已消缺
                    this.buttonText = '查看';
                    this.title = '缺陷已消缺';
                    this.buttonVisible = true;
                    this.buttonsubmitVisible = true;
                    //是否有驳回
                    this.hasRejectBtn = false;
                    break;
                default:
                    this.$message({
                        message: '你没有权限',
                        type: 'error'
                    });
                    return;
            }
            if (!this.buttonsubmitVisible) {
                this.closeForm();
            }
        },
        async show(data) {
            await this.handle(data);
            this.$nextTick(async () => {
                this.closeForm();
                this.listVisible = false;
                this.buttonVisible = true;
                this.buttonsubmitVisible = false;
                this.hasRejectBtn = false;
            });
        },
        activateDeriveButton() {
            //激活所有导出按钮
            this.$refs.handle.activateDeriveButton();
            this.$refs.audit.activateDeriveButton();
            this.$refs.flawApplication.activateDeriveButton();
            this.$refs.receipt.activateDeriveButton();
        },
        closeForm() {
            //关闭所有表单，禁用
            this.$refs.flawApplication.closeForm();
            this.$refs.handle.closeForm();
            this.$refs.audit.closeForm();
            this.$refs.receipt.closeForm();
        },
        reject() {
            let code = this.code;
            this.$confirm(`确定驳回`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const dataForm = {};
                //设置编号code
                dataForm.code = code;
                dataForm.flawApplication = this.$refs.flawApplication.createForm(); //缺陷申请单
                let isHandleForm, isAuditForm, isReceiptForm;
                if (this.auditVisible) {
                    //审核
                    this.$refs.audit.$refs.auditForm.validate((is) => {
                        isAuditForm = is;
                    });
                    dataForm.auditForm = this.$refs.audit.createForm();
                    let auditTime = this.$dayjs().format('YYYY-MM-DD HH:mm:ss');
                    dataForm.auditForm.time = auditTime;
                }
                if (this.handleVisible) {
                    //工作任务
                    this.$refs.handle.$refs.handleForm.validate((is) => {
                        isHandleForm = is;
                    });
                    dataForm.handleForm = this.$refs.handle.createForm();
                }
                if (this.receiptVisible) {
                    //缺陷回执单
                    this.$refs.receipt.$refs.receiptForm.validate((is) => {
                        isReceiptForm = is;
                    });
                    dataForm.receiptForm = this.$refs.receipt.createForm();
                    let receiptTime = this.$dayjs().format('YYYY-MM-DD HH:mm:ss');
                    dataForm.receiptForm.time = receiptTime;
                }
                let nextRoles = [];
                //全部驳回到运维
                nextRoles.push('KZYW');
                const RceiptApplication = {
                    definitionCode: 'elec_dispatcn_flaw',
                    operationType: 'reject',
                    nodeCode: this.rejectNodeFrom,
                    rejectNodeCode: this.rejectNodeTo,
                    data: dataForm,
                    extraProp: {
                        handleResult: dataForm.receiptForm != null ? dataForm.receiptForm.handleResult : '',
                        notifyUnits: dataForm.handleForm != null ? dataForm.handleForm.notifyUnits : '',
                        code: dataForm.code
                    },
                    nextRoles
                };
                RceiptApplication.instanceId = this.curInstances[0].id;
                RceiptApplication.nodeCode = this.curInstances[0].nextNode.code;
                this.$client.workflowInstanceProcess(RceiptApplication).then(async () => {
                    await this.refreshDataList();
                });
            });
        },
        createRceiptApplication() {
            let code = this.code;
            this.$confirm(`确定提交`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.createRceiptApplicationFrom(code);
            });
        },
        async createRceiptApplicationFrom(code) {
            let nextRoles = [];
            let curNodeCode = this.curInstances[0].curNode.code;
            //该环节是否生成缺陷code
            let isSaveFlawCode = false;
            let flawCode = {
                instanceId: '',
                code: '',
            };
            switch (curNodeCode) {
                case 'elec_dispatcn_flaw_add_apply':
                    nextRoles.push(this.Roles.filter((item) => item.roleCode === 'FLAW_MANAGE')[0].roleId); //下一个流程交给缺陷管理员
                    break;
                case 'elec_dispatcn_flaw_add_work_issued':
                    nextRoles.push(this.Roles.filter((item) => item.roleCode === 'KZYW')[0].roleId); //下一个流程交给运维
                    break;
                case 'elec_dispatcn_flaw_add_receipt':
                    nextRoles.push(this.Roles.filter((item) => item.roleCode === 'FJB')[0].roleId); //下一个流程交给发计部
                    nextRoles.push(this.Roles.filter((item) => item.roleCode === 'FLAW_MANAGE')[0].roleId); //下一个流程交给缺陷管理员
                    break;
                default:
                    break;
            }
            const dataForm = {};
            //设置缺陷code
            dataForm.code = code;
            dataForm.flawApplication = this.$refs.flawApplication.createForm(); //缺陷申请单

            let isHandleForm, isAuditForm, isReceiptForm;

            if (this.auditVisible) {
                //审核
                this.$refs.audit.$refs.auditForm.validate((is) => {
                    isAuditForm = is;
                });
                dataForm.auditForm = this.$refs.audit.createForm();
                //设置审核时间、生成编号
                if (curNodeCode === 'elec_dispatcn_flaw_add_apply') {
                    let auditTime = this.$dayjs().format('YYYY-MM-DD HH:mm:ss');
                    dataForm.auditForm.time = auditTime;

                    if (!this.code.startsWith('QX')) {
                        //判断该流程是否已经生成code
                        let res = await this.$client.queryByInstanceId(this.curInstances[0].id);
                        if (res.data && res.data.length) {
                            dataForm.code = res.data[0].code;
                        } else {
                            let codeRes = await this.$client.queryThisMonthMaxCode();
                            dataForm.code = codeRes.data;
                            isSaveFlawCode = true;
                            flawCode.instanceId = this.curInstances[0].id;
                            flawCode.code = dataForm.code;
                        }
                    }
                }
            } else {
                //其他情况下回显审核
                dataForm.auditForm = this.$refs.audit.createForm();
            }
            if (this.handleVisible) {
                //工作任务
                this.$refs.handle.$refs.handleForm.validate((is) => {
                    isHandleForm = is;
                });
                dataForm.handleForm = this.$refs.handle.createForm();
            }
            if (this.receiptVisible) {
                //缺陷回执单
                this.$refs.receipt.$refs.receiptForm.validate((is) => {
                    isReceiptForm = is;
                });
                dataForm.receiptForm = this.$refs.receipt.createForm();
                //设置审核时间
                if (curNodeCode === 'elec_dispatcn_flaw_add_receipt') {
                    let receiptTime = this.$dayjs().format('YYYY-MM-DD HH:mm:ss');
                    dataForm.receiptForm.time = receiptTime;
                }
            } else {
                //其他情况下回显
                dataForm.receiptForm = this.$refs.receipt.createForm();
            }
            if (
            //进行表单验证
                (!this.handleVisible || (this.handleVisible && isHandleForm)) &&
                    (!this.auditVisible || (this.auditVisible && isAuditForm)) &&
                    (!this.receiptVisible || (this.receiptVisible && isReceiptForm))
            ) {
                console.log('验证完成');
            } else {
                console.log('验证失败'); //验证失败，表单验证清除
                this.$message({
                    message: '请确认表单输入完整',
                    type: 'error'
                });
                return;
            }
            const RceiptApplication = {
                definitionCode: 'elec_dispatcn_flaw',
                nodeCode: '',
                operationType: 'saveAndNext',
                rejectNodeCode: '',
                data: dataForm,
                extraProp: {
                    handleResult: dataForm.receiptForm != null ? dataForm.receiptForm.handleResult : '',
                    notifyUnits: dataForm.handleForm != null ? dataForm.handleForm.notifyUnits : '',
                    code: dataForm.code,
                },
                nextRoles: this.nextRoles
            };
            RceiptApplication.instanceId = this.curInstances[0].id;
            RceiptApplication.nodeCode = this.curInstances[0].nextNode.code;

            this.$client.workflowInstanceProcess(RceiptApplication).then(async () => {
                await this.refreshDataList();
                if (isSaveFlawCode) {
                    this.$client.saveFlawCode(flawCode);
                }
            });
        },
        deleteForm(data) {
            this.$confirm(`确定删除选中的数据`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let ids = [data.instanceId];
                if (this.deletebutton(data)) {
                    this.$client.workflowDelete(ids).then((res) => {
                        if (res.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 2000
                            });
                        }
                        this.refreshDataList();
                    });
                }
            });
        },
        handlebutton(data) {
            let instance = this.instances.filter((item) => item.records[item.records.length - 1].id === data.id);
            let isShow = false;
            if (instance.length > 0 && this.$store.state.userInfo.roleCodeList != null) {
                let businesStatus = this.businesStatusOption.filter((item) => {
                    if (instance.length > 0 && instance[0].nextNode != null) {
                        return item.value === instance[0].nextNode.code;
                    }
                    return false;
                });
                let code = businesStatus.length > 0 ? businesStatus[0].code : 'elec_dispatcn_flaw_ok';
                switch (code) {
                    case 'elec_dispatcn_flaw_1': //待填写
                        if (this.$store.state.userInfo.roleCodeList.includes('KZYW')) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_flaw_add_apply': //待审核
                        if (this.$store.state.userInfo.roleCodeList.includes('FLAW_MANAGE')) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_flaw_add_work_issued': //执行中
                        if (
                            this.$store.state.userInfo.roleCodeList.includes('KZYW')
                        ) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_flaw_add_receipt': //待消缺
                        if (
                            this.$store.state.userInfo.roleCodeList.includes('FLAW_MANAGE') ||
                                this.$store.state.userInfo.roleCodeList.includes('FJB')
                        ) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_flaw_audit': //已消缺
                        if (
                            this.$store.state.userInfo.roleCodeList.includes('FLAW_MANAGE') ||
                                this.$store.state.userInfo.roleCodeList.includes('FJB')
                        ) {
                            isShow = true;
                        }
                        break;
                    default:
                        break;
                }
            }
            return isShow;
        },
        deletebutton(data) {
            let instance = this.instances.filter((item) => item.records[item.records.length - 1].id === data.id);
            let isShow = false;
            if (instance.length > 0 && this.$store.state.userInfo.roleCodeList != null) {
                let businesStatus = this.businesStatusOption.filter((item) => {
                    if (instance.length > 0 && instance[0].nextNode != null) {
                        return item.value === instance[0].nextNode.code;
                    }
                    return false;
                });
                let code = businesStatus.length > 0 ? businesStatus[0].code : 'elec_dispatcn_flaw_ok';
                switch (code) {
                    case 'elec_dispatcn_flaw_1': //填写
                        if (
                            this.$store.state.userInfo.roleCodeList.includes('FLAW_MANAGE') ||
                                this.$store.state.userInfo.roleCodeList.includes('KZYW')
                        ) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_flaw_add_apply': //待审核
                        if (
                            this.$store.state.userInfo.roleCodeList.includes('FLAW_MANAGE') ||
                                this.$store.state.userInfo.roleCodeList.includes('KZYW')
                        ) {
                            isShow = true;
                        }
                        break;
                    case 'elec_dispatcn_flaw_add_work_issued': //执行中
                        if (this.$store.state.userInfo.roleCodeList.includes('FLAW_MANAGE')) {
                            isShow = false;
                        }
                        break;
                    case 'elec_dispatcn_flaw_add_receipt': //待消缺
                        break;
                    case 'elec_dispatcn_flaw_audit':
                        // isShow = true;
                        break;
                    default:
                        // isShow = true;
                        break;
                }
            }
            return isShow;
        },
        handleTreeData(cout, indexList) {
            let num = 0;
            let str = '';
            return function handleData2(list) {
                //对一个list递归cout次
                if (num < cout) {
                    let parent = list.filter((item) => item.id === indexList[num]);
                    num++;

                    if (parent.length > 0 && parent[0].children != null) {
                        let children = handleData2(parent[0].children);
                        return children;
                    }
                    if (parent.length === 1) {
                        return parent;
                    }
                }
                return list;
            };
        },
        //流程对应表单
        isWorkForm(code) {
            switch (code) {
                case 'elec_dispatcn_flaw_1': //待填写
                    this.FlawApplictionVisible = true;
                    this.handleVisible = false;
                    this.auditVisible = false;
                    break;
                case 'elec_dispatcn_flaw_add_apply': //待审核
                    this.FlawApplictionVisible = true;
                    this.handleVisible = false;
                    this.auditVisible = true;
                    break;
                case 'elec_dispatcn_flaw_add_work_issued': //执行中
                    this.handleVisible = true;
                    this.auditVisible = true;
                    this.FlawApplictionVisible = true;
                    this.receiptVisible = false;
                    break;
                case 'elec_dispatcn_flaw_add_receipt': //待消缺
                    this.FlawApplictionVisible = true;
                    this.handleVisible = true;
                    this.auditVisible = true;
                    this.receiptVisible = true;
                    break;
                case 'elec_dispatcn_flaw_audit': //已消缺
                    this.FlawApplictionVisible = true;
                    this.handleVisible = true;
                    this.auditVisible = true;
                    this.receiptVisible = true;
                    break;
                default:
                    this.FlawApplictionVisible = false;
                    this.handleVisible = false;
                    this.auditVisible = false;
                    this.receiptVisible = false;
                    break;
            }
        },
        //激活并赋值
        activateAndGiveForm(dataForm, isFill) {
            this.code = dataForm[0].code ? dataForm[0].code : '--';
            //顺序：FlawApplictionVisible -> handleVisible -> receiptVisible -> auditVisible
            if (this.FlawApplictionVisible) {
                //基本信息填写
                this.$refs.flawApplication.activateForm();
                this.$refs.flawApplication.init(dataForm[0].flawApplication, isFill);
            }

            //处理情况填写
            if (!this.auditVisible || !this.receiptVisible) {
                if (this.handleVisible) {
                    this.$refs.handle.activateForm(false);
                    this.$refs.handle.init(dataForm[0].handleForm);
                }
            } else {
                this.$refs.handle.activateForm(true);
                this.$refs.handle.init(dataForm[0].handleForm);
            }

            //处理审核、消缺模块显示
            //消缺
            if (this.receiptVisible) {
                this.$refs.receipt.activateForm(false);
                this.$refs.receipt.init(dataForm[0].receiptForm);
            } else if (this.handleVisible) {
                //消缺 处理驳回显示
                this.$refs.receipt.activateForm(true);
                this.$refs.receipt.init(dataForm[0].receiptForm);
            }
            //审核
            if (this.handleVisible) {
                //已经过了审核阶段则不可编辑
                this.$refs.audit.activateForm(true);
                this.$refs.audit.init(dataForm[0].auditForm);
            } else if (this.auditVisible) {
                //审核 可编辑
                this.$refs.audit.activateForm(false);
                this.$refs.audit.init(dataForm[0].auditForm);
            } else {
                //信息填写阶段 显示驳回信息
                this.$refs.audit.activateForm(true);
                this.$refs.audit.init(dataForm[0].auditForm);
            }
        },

    }
};
</script>
<style lang="less" scoped>
    .card_table .el-input--mini {
        /deep/ .el-input__inner {
            border: none;
            // background: transparent;
            // outline: none;
        }
    }

    .customcard {
        padding: 0;
    }

    .detailTitle {
        padding: 10px;
    }

    .derive_button {
        position: static;
        float: right;
        top: 100px;
    }

    .el-card .is-always-shadow {
        .el-card__body {
            padding: 0;

            .customcard {
                .el-card__body {
                    padding: 20px;
                }
            }
        }
    }

    form.el-form {
        .ulbox {
            .card {
                margin: 0;
            }
        }

        .el-date-editor {
            width: 100%;
        }
    }

    .submit-btn-group {
        text-align: center;
        margin-bottom: 20px;
    }

    .el-form-item {
        margin: 0px;
    }

    .el-textarea__inner,
    .el-input_inner {
        font-size: 14px;
        color: #d1d1d1;
    }
</style>
