<template>
    <div>
        <el-card class="card-pub-notice card-ext-center-title customcard">
            <el-button type="primary" @click="derive()" v-if="deriveButton" class="derive_button">导出</el-button>
            <el-form :model="dataForm" :rules="dataRule" ref="auditForm" :disabled="auditDisable">
                <el-row :gutter="24">
                    <el-col :span="6">
                        <el-form-item :label="'审核时间'" prop="time">
                            <el-input v-model="dataForm.time" placeholder="审核时间" disabled> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="24">
                        <el-form-item :label="'审核意见'" prop="content">
                            <el-input v-model="dataForm.content" placeholder="请输入审核意见" type="textarea"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <br />
    </div>
</template>

<script>
import {previewPdf, fileDownload} from '@/utils';

export default {
    name: 'handle',
    components: {},
    data() {
        return {
            dataRule: {
                content: [{required: true, message: '请填写审核意见', trigger: 'blur'}],
            },
            dataForm: {
                time: '',
                content: '',
            },
            auditDisable: false,
            instance: {},
            deriveButton: false,
        };
    },
    created() {},
    methods: {
        async init(dataForm) {
            if (dataForm != null) {
                this.dataForm = dataForm;
            }
            this.closeDeriveButton();
        },
        activateForm(auditDisable) {
            this.clearform();
            this.auditDisable = auditDisable;
        },
        closeForm() {
            this.auditDisable = true;
            this.$refs.auditForm.clearValidate();
        },
        createForm() {
            return this.dataForm;
        },
        derive() {
            fileDownload('/business/ElecDispatchFlawManage/workTaskInformDownload', {
                method: 'POST',
                data: this.dataForm,
            });
        },
        activateDeriveButton() {
            this.deriveButton = true;
        },
        closeDeriveButton() {
            this.deriveButton = false;
        },
        clearform() {
            this.dataForm = {
                time: '',
                content: '',
            };
        },
    },
};
</script>
<style lang="less" scoped>
.el-form-item {
    margin: 0px;
}
form.el-form {
    .el-date-editor {
        width: 100%;
    }
}
</style>
